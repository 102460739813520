import React from "react"
import types from "prop-types"
import { ListSelect } from "components/shared/selects"

export const ALL_ROLES = "All Roles"
export const ADMIN_ROLE = "Admin"
export const NON_ADMIN_ROLE = "Non Admin"

const USER_ROLES = [
  ALL_ROLES,
  ADMIN_ROLE,
  NON_ADMIN_ROLE,
]

const optionsFromList = (options) => options.map((opt) => ({ label: opt, value: opt }))

const UserRoleFilter = ({ onChange, value }) => (
  <ListSelect
    onChange={onChange}
    options={USER_ROLES}
    optionsFromList={optionsFromList}
    style={{ width: "14rem" }}
    value={value}
  />
)

UserRoleFilter.propTypes = {
  onChange: types.func.isRequired,
  value: types.oneOf(USER_ROLES).isRequired,
}

export default UserRoleFilter
