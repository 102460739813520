import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { formShape } from "utils/propTypeShapes"
import { TaskSubformIcon, TaskGeneralIcon } from "shared/icons"

const TaskTokenTitle = ({ subform = {}, title = "", className = "" }) => {
  const isSubform = !!subform
  const formTitle = title?.length ? title : "General Task"
  const Icon = isSubform ? TaskSubformIcon : TaskGeneralIcon

  return (
    <span className={clsx(className, "text-token min-h-9 font-medium p-1.5")}>
      <Icon className="shrink-0 mr-1.5 h-6 w-6 bg-white" />
      <span>{ isSubform ? subform.title : formTitle}</span>
    </span>
  )
}

TaskTokenTitle.propTypes = {
  className: types.string,
  subform: formShape,
  title: types.string,
}

export default TaskTokenTitle
