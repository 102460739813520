import React from "react"
import types from "prop-types"
import clsx from "clsx"

const DetailBannerInfo = ({
  className = "", name, hideName = false, children,
}) => (
  <div className={className}>
    <div className="text-sm font-medium text-dark mb-0.5">
      <span className={clsx("whitespace-nowrap", hideName && "md:invisible")}>{name}</span>
    </div>
    <div className="lg:min-h-[34px] lg:flex lg:items-center font-medium w-full">
      { children }
    </div>
  </div>
)

DetailBannerInfo.propTypes = {
  className: types.string,
  name: types.string.isRequired,
  hideName: types.bool,
  children: types.node.isRequired,
}

export default DetailBannerInfo
