import React, { useState } from "react"
import types from "prop-types"
import clsx from "clsx"

import "./FormInputContainerStyle.scss"

const FormInputContainer = ({
  children,
  description = "",
  label = "",
  showDescription,
  required = false,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div className={clsx("form-field", isFocused && "is-active")}>
      <div
        className="form-input-control"
        onFocusCapture={() => setIsFocused(true)}
        onBlurCapture={() => setIsFocused(false)}
      >
        <label htmlFor={`${label}-${children.props.question.uuid}`}>
          <div className={clsx(required && "required-prompt", "form-input-label")}>
            { label }
          </div>
          {
            showDescription && description && (
              <div className="form-input-description">{ description }</div>
            )
          }
          { children }
        </label>
      </div>
    </div>
  )
}

FormInputContainer.propTypes = {
  children: types.node.isRequired,
  description: types.string,
  label: types.string,
  showDescription: types.bool.isRequired,
  required: types.bool,
}

export default FormInputContainer
