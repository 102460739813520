import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { questionShape } from "utils/propTypeShapes"
import { FormsyTextInput } from "shared/inputs"

const emailValidations = ({ edit, required }) => {
  const validations = {
    isEmail: true,
    noExcelMetacharacters: true,
    noHTML: true,
  }

  if (!edit && required) {
    validations.isNotBlankString = true
  }

  return validations
}

const EmailAnswerField = ({
  className = "",
  disabled = false,
  edit = false,
  hasBaseInputStyle = false,
  initialValue = null,
  placeholder = "",
  question,
  ...rest
}) => {
  const { required, uuid } = question

  return (
    <FormsyTextInput
      className={clsx("form-input", className)}
      name={uuid}
      validationErrors={{ isEmail: "Invalid email address" }}
      validations={emailValidations({ edit, required })}
      value={initialValue || ""}
      disabled={disabled}
      placeholder={placeholder}
      hasBaseInputStyle={hasBaseInputStyle}
      {...rest}
    />
  )
}

EmailAnswerField.propTypes = {
  edit: types.bool,
  question: questionShape.isRequired,
  initialValue: types.string,
  disabled: types.bool,
  className: types.string,
  placeholder: types.string,
  hasBaseInputStyle: types.bool,
}

export default EmailAnswerField
