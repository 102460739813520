import React from "react"
import types from "prop-types"
import { SidebarCollapsibleContent } from "components/shared/Sidebar"
import AccessUserList from "./AccessUserList"

const FormSubmissionAccessUsers = ({
  membersWithGrant, headerText, description, emptyListText, initiallyExpanded = false,
}) => (
  <SidebarCollapsibleContent title={headerText} initiallyExpanded={initiallyExpanded}>
    <>
      <p className="mb-4 text-sm leading-6">
        { description }
      </p>
      <AccessUserList membersWithGrant={membersWithGrant} emptyListText={emptyListText} />
    </>
  </SidebarCollapsibleContent>
)

FormSubmissionAccessUsers.propTypes = {
  membersWithGrant: types.arrayOf(types.object).isRequired,
  headerText: types.string.isRequired,
  description: types.string.isRequired,
  emptyListText: types.string.isRequired,
  initiallyExpanded: types.bool,
}

export default FormSubmissionAccessUsers
