import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useTaskFormContext } from "contexts/TaskFormContext"
import { DateInput } from "shared/inputs"
import FormsyValidation from "shared/FormsyValidation"

const TaskDueDate = ({ className = "" }) => {
  const { taskDueDate, updateTaskDueDate } = useTaskFormContext()

  return (
    <div className={clsx("relative", className)}>
      <span className="block mb-1 font-semibold required-prompt">Due Date</span>
      <DateInput
        className="!text-sm"
        onChange={updateTaskDueDate}
        value={taskDueDate}
      />
      <FormsyValidation
        name="task.due_date"
        required
        validations={{
          isNotBlankString: { errorMessage: "Invalid date format: must be MM/DD/YYYY" },
          matchRegexp: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
        }}
        validationErrors={{
          isDefaultRequiredValue: "required",
          matchRegexp: "Invalid date format: must be MM/DD/YYYY",
        }}
        value={taskDueDate || ""}
      />
    </div>
  )
}

TaskDueDate.propTypes = {
  className: types.string,
}

export default TaskDueDate
