/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { workflowConditionShape } from "utils/propTypeShapes"
import {
  getFormQuestions,
  getDepartments,
  getActiveOrMatchingDepartments,
  getActiveOrMatchingFacilities,
  getFacilityGroupCodes,
  valueMatches,
} from "reduxSlices/formBuilderSlice"
import {
  IS_ANY_OF_FACILITIES_PROPERTY_OP,
  IS_DEPARTMENT_PROPERTY_OP,
  IS_NOT_DEPARTMENT_PROPERTY_OP,
  IS_EMPTY_PROPERTY_OP,
  IS_NOT_EMPTY_PROPERTY_OP,
  HAS_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP,
  DOES_NOT_HAVE_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP,
} from "utils/workflowHelpers"
import { Select, InactiveOptionsItemRenderer } from "shared/selects"
import { MultiSelect } from "shared/multiSelects"

const DepartmentSelect = ({ value, onChange, facilityFilterId }) => {
  const departmentOptionsList = useSelector(getActiveOrMatchingDepartments(value))
    .filter((department) => valueMatches(department.facilityId, facilityFilterId))
    .map((department) => ({
      value: department.id,
      label: department.name,
      active: department.active,
    }))
  return (
    <Select
      options={departmentOptionsList}
      itemRenderer={InactiveOptionsItemRenderer}
      value={value}
      style={{ height: "32px" }}
      placeholder="Select department"
      onChange={onChange}
    />
  )
}

const FacilitySelect = ({ value, onChange }) => {
  const facilityOptionsList = useSelector(getActiveOrMatchingFacilities(value))
    .map((facility) => ({
      value: facility.id,
      label: facility.name,
      active: facility.active,
    }))

  return (
    <Select
      options={facilityOptionsList}
      itemRenderer={InactiveOptionsItemRenderer}
      value={value}
      style={{ height: "32px" }}
      placeholder="Select facility"
      onChange={onChange}
    />
  )
}

const FacilitiesSelect = ({ value, onChange }) => {
  const facilityOptionsList = useSelector(getActiveOrMatchingFacilities(value))
    .map((facility) => ({
      value: facility.id,
      label: facility.name,
      active: facility.active,
    }))

  return (
    <MultiSelect
      options={facilityOptionsList}
      itemRenderer={InactiveOptionsItemRenderer}
      values={value}
      style={{ minHeight: "32px" }}
      placeholder="Select facilities"
      onChange={onChange}
    />
  )
}

const FacilityGroupCodesSelect = ({ value, onChange }) => {
  const facilityGroupCodeOptionsList = useSelector(getFacilityGroupCodes).map((facilityGroupCode) => ({
    value: facilityGroupCode.id,
    label: facilityGroupCode.name,
  }))

  return (
    <MultiSelect
      options={facilityGroupCodeOptionsList}
      values={value}
      style={{ minHeight: "32px" }}
      placeholder="Select facility group codes"
      onChange={onChange}
    />
  )
}

const LocationQuestionType = ({ condition, changeConditionValue }) => {
  const [facilityFilterId, setFacilityFilterId] = useState(null)
  const departments = useSelector(getDepartments)
  const questions = useSelector(getFormQuestions)
  const question = questions.find(({ uuid }) => uuid === condition.subProperty?.questionUuid)

  useEffect(() => {
    if (condition.value?.departmentId) {
      const department = departments.find(({ id }) => id === condition.value.departmentId)
      setFacilityFilterId(department?.facilityId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (![IS_DEPARTMENT_PROPERTY_OP, IS_NOT_DEPARTMENT_PROPERTY_OP].includes(condition.operator)) {
      setFacilityFilterId(null)
    }
  }, [condition.operator, facilityFilterId])

  if ([IS_EMPTY_PROPERTY_OP, IS_NOT_EMPTY_PROPERTY_OP].includes(condition.operator)) {
    return null
  }

  if ([IS_DEPARTMENT_PROPERTY_OP, IS_NOT_DEPARTMENT_PROPERTY_OP].includes(condition.operator)) {
    return (
      <>
        <FacilitySelect
          key={condition.operator}
          value={facilityFilterId}
          question={question}
          onChange={setFacilityFilterId}
        />
        <DepartmentSelect
          key={facilityFilterId}
          value={condition.value?.departmentId}
          question={question}
          facilityFilterId={facilityFilterId}
          onChange={(departmentId) => changeConditionValue({ departmentId })}
        />
      </>
    )
  }

  if (condition.operator === IS_ANY_OF_FACILITIES_PROPERTY_OP) {
    return (
      <FacilitiesSelect
        value={condition?.value?.facilityIds || []}
        question={question}
        onChange={(facilityIds) => changeConditionValue({ facilityIds })}
      />
    )
  }

  if (condition.operator === HAS_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP
    || condition.operator === DOES_NOT_HAVE_ANY_OF_FACILITY_GROUP_CODES_PROPERTY_OP) {
    return (
      <FacilityGroupCodesSelect
        value={condition?.value?.facilityGroupCodeIds || []}
        question={question}
        onChange={(facilityGroupCodeIds) => changeConditionValue({ facilityGroupCodeIds })}
      />
    )
  }

  // Return for IS_FACILITY_PROPERTY_OP and IS_NOT_FACILITY_PROPERTY_OP
  return (
    <FacilitySelect
      value={facilityFilterId || condition.value?.facilityId}
      question={question}
      onChange={(facilityId) => changeConditionValue({ facilityId })}
    />
  )
}

LocationQuestionType.propTypes = {
  condition: workflowConditionShape.isRequired,
  changeConditionValue: types.func.isRequired,
}

export default LocationQuestionType
