import React from "react"
import types from "prop-types"
import { PrimaryButton } from "shared/buttons"

const SaveTaskButton = ({ text = "Add this Task" }) => (
  <PrimaryButton
    className="w-50 ml-3 bg-dark"
    text={text}
    type="submit"
  />
)

SaveTaskButton.propTypes = {
  text: types.string,
}

export default SaveTaskButton
