/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import types from "prop-types"
import { Select } from "shared/selects"

const selectOptions = (publicForms) => publicForms.map((publicForm) => (
  { label: publicForm.title, value: publicForm.slug }
))

const SubmissionFormTypeSelector = ({
  className = "", onChange, publicForms = [], value = undefined,
}) => (
  <div className="submission-form-type-selector">
    <label className="field-name">
      <span className="block mb-2">New Form Type</span>
      <Select
        className={className}
        onChange={onChange}
        options={selectOptions(publicForms)}
        value={value}
      />
    </label>
  </div>
)

SubmissionFormTypeSelector.propTypes = {
  className: types.string,
  onChange: types.func.isRequired,
  publicForms: types.arrayOf(types.object),
  value: types.string,
}

export default SubmissionFormTypeSelector
