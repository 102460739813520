import React from "react"
import types from "prop-types"
import { ListSelect } from "components/shared/selects"

export const ALL_STATUS = "All"
export const ACTIVE_STATUS = "Active"
export const INACTIVE_STATUS = "Inactive"

const USER_ACTIVE_STATUSES = [
  ALL_STATUS,
  ACTIVE_STATUS,
  INACTIVE_STATUS,
]

const optionsFromList = (options) => options.map((opt) => ({ label: opt, value: opt }))

const UserActiveStatusFilter = ({ onChange, value }) => (
  <ListSelect
    onChange={onChange}
    options={USER_ACTIVE_STATUSES}
    optionsFromList={optionsFromList}
    style={{ width: "14rem" }}
    value={value}
  />
)

UserActiveStatusFilter.propTypes = {
  onChange: types.func.isRequired,
  value: types.oneOf(USER_ACTIVE_STATUSES).isRequired,
}

export default UserActiveStatusFilter
