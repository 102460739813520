import React from "react"
import types from "prop-types"
import { questionShape } from "utils/propTypeShapes"
import FormattedNoteAnswer from "./FormattedNoteAnswer"

const NoteAnswer = ({ question, isNewQuestion = false }) => {
  if (isNewQuestion) return null

  const { attributes: { note } } = question

  return (
    <div className="answer">
      <FormattedNoteAnswer note={note} />
    </div>
  )
}

NoteAnswer.propTypes = {
  isNewQuestion: types.bool,
  question: questionShape.isRequired,
}

export default NoteAnswer
