import React from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import { useSelector } from "react-redux"
import { groups as getGroups } from "reduxSlices/formSubmissionSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable max-len */
import TaskGroupList from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskGroupList"
/* eslint-enable max-len */

const TaskMemberGroupList = ({ className = "" }) => {
  const groups = useSelector(getGroups)

  const { removeMemberGroupId, taskMemberGroupIds } = useTaskFormContext()

  if (taskMemberGroupIds.length === 0) return null

  const memberGroups = sortBy(
    taskMemberGroupIds.map((memberGroupId) => (
      groups.find((group) => group.id === memberGroupId)
    )),
    ["name"],
  )

  return (
    <div className={className}>
      <TaskGroupList className="mb-2" removeGroup={removeMemberGroupId} groups={memberGroups} />
      <p className="text-xs text-dark md:w-4/5">
        Each member group will have each of its members added to the task individually.
        Each group member will also be granted individual access to the form submission
        and be made a member of any tasks selected under &quot;Access to Other Tasks&quot;.
      </p>
    </div>
  )
}

TaskMemberGroupList.propTypes = {
  className: types.string,
}

export default TaskMemberGroupList
