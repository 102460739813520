import React, { useMemo } from "react"
import types from "prop-types"
import clsx from "clsx"
import { v4 as makeUuid } from "uuid"
import { SEGMENTS } from "utils/departmentSegmentHelpers"
import { Checkbox } from "components/shared/checkboxes"

const TaskDepartmentSegmentCheckbox = ({
  className = "",
  segment,
  toggleSelection,
  disabled,
  selected,
  groupIdentifier,
}) => {
  const uuid = useMemo(makeUuid, [segment])
  const checkboxId = `task-${groupIdentifier}-department-segment-checkbox-${segment}-${uuid}`

  return (
    <div className={clsx(className, "flex items-center")}>
      <Checkbox
        className={clsx("mr-2", disabled && "cursor-not-allowed")}
        disabled={disabled}
        name={checkboxId}
        onChange={toggleSelection(segment)}
        uuid={checkboxId}
        value={selected}
      />
      <label
        className={clsx(disabled ? "cursor-not-allowed" : "cursor-pointer")}
        htmlFor={checkboxId}
      >
        Segment {segment.toUpperCase()}
      </label>
    </div>
  )
}

TaskDepartmentSegmentCheckbox.propTypes = {
  className: types.string,
  segment: types.oneOf(SEGMENTS).isRequired,
  toggleSelection: types.func.isRequired,
  disabled: types.bool.isRequired,
  selected: types.bool.isRequired,
  groupIdentifier: types.string.isRequired,
}

export default TaskDepartmentSegmentCheckbox
