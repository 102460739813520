import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { PrimaryButton } from "shared/buttons"

const AddTaskButton = ({ className = "", openForm }) => (
  <PrimaryButton
    className={clsx(className, "bg-navy")}
    text="+ Add a Task"
    onClick={openForm}
  />
)

AddTaskButton.propTypes = {
  className: types.string,
  openForm: types.func.isRequired,
}

export default AddTaskButton
