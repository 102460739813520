import React from "react"
import types from "prop-types"
import { questionShape } from "utils/propTypeShapes"
import QuestionBranchingConditionalCheck from "Forms/FormElements/QuestionBranchingConditionalCheck"
import QuestionAnswerField from "views/Forms/FormElements/QuestionAnswerField"
import SubmissionQuestion from "../../SubmissionQuestion"

const SubmissionEditQuestion = ({ question, formAnswers, disabled = false }) => {
  const {
    prompt,
    description,
    conditionals = null,
  } = question

  return (
    <QuestionBranchingConditionalCheck conditionals={conditionals} formAnswers={formAnswers}>
      <SubmissionQuestion
        className="dark edit-submission-question rounded relative w-full"
        prompt={prompt}
        description={description}
      >
        <QuestionAnswerField
          question={question}
          formAnswers={formAnswers}
          disabled={disabled}
          edit
        />
      </SubmissionQuestion>
    </QuestionBranchingConditionalCheck>
  )
}

SubmissionEditQuestion.propTypes = {
  question: questionShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formAnswers: types.object.isRequired,
  disabled: types.bool,
}

export default SubmissionEditQuestion
