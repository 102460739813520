import React, { useState } from "react"
import types from "prop-types"
import clsx from "clsx"
import { connect } from "react-redux"
import { FormSubmissionAuditTrailContextProvider } from "contexts/FormSubmissionAuditTrailContext"
import LoadMoreButton from "./LoadMoreButton"
import AuditsHeader from "./AuditsHeader"
import FormSubmissionAuditTrailGroups from "./FormSubmissionAuditTrailGroups"

const AuditTrailContent = ({ formSubmissionSlug = "" }) => {
  const [hiddenSidebar, setHiddenSidebar] = useState(true)
  const toggleSidebar = () => setHiddenSidebar(!hiddenSidebar)

  return (
    <FormSubmissionAuditTrailContextProvider formSubmissionSlug={formSubmissionSlug}>
      <div className="relative grow">
        <div className="inner-container mx-auto max-w-screen-xl">
          <AuditsHeader hiddenSidebar={hiddenSidebar} toggleSidebar={toggleSidebar} />
          <div className={clsx(!hiddenSidebar && "pl-80")}>
            <FormSubmissionAuditTrailGroups />
            <LoadMoreButton />
          </div>
        </div>
      </div>
    </FormSubmissionAuditTrailContextProvider>
  )
}

AuditTrailContent.propTypes = {
  formSubmissionSlug: types.string,
}

const mapStateToProps = (state) => (
  {
    formSubmissionSlug: state.formSubmission.data.slug,
  }
)

export default connect(mapStateToProps, {})(AuditTrailContent)
