import React, { useState, useMemo } from "react"
import types from "prop-types"
import { taskDataForTaskFormShape } from "utils/propTypeShapes"
import { DEFAULT_SEGMENTS, SEGMENTS } from "utils/departmentSegmentHelpers"
import { accessOverview } from "reduxSlices/formSubmissionSlice"
import { useSelector } from "react-redux"
import { currentUserId as getCurrentUserId, currentUserIsPhpAdmin as getCurrentUserIsPhpAdmin } from "reduxSlices/sessionSlice"
import { getTaskNotifySettings } from "reduxSlices/currentSettingsSlice"
import {
  ASSIGNEES_ON_ASSIGNMENT, ASSIGNEES_ON_COMPLETE, MEMBERS_ON_ASSIGNMENT, MEMBERS_ON_COMPLETE,
} from "utils/generalSettingsHelpers"
import { find, isEmpty, reject } from "lodash-es"
import { updateDepartmentInCollection } from "utils/taskHelpers"
import TaskFormContext from "../TaskFormContext"

const EMPTY_TASK = {
  assigneeDepartments: [],
  assigneeGroupIds: [],
  assigneeIds: [],
  description: "",
  dueDate: "",
  manageAccessTaskIds: [],
  memberDepartments: [],
  memberGroupIds: [],
  memberIds: [],
  notifyGroupIds: [],
  notifyIds: [],
  notifyOnCompleteGroupIds: [],
  notifyOnCompleteIds: [],
  relativeDueDate: {},
  subformId: null,
  title: "",
}

const TaskFormContextProvider = ({ children, task: propTask = {} }) => {
  const currentUserId = useSelector(getCurrentUserId)
  const currentUserIsPhpAdmin = useSelector(getCurrentUserIsPhpAdmin)

  const { members = [] } = useSelector(accessOverview)

  const defaultOrgNotifySettings = new Map(
    useSelector(getTaskNotifySettings)
      .map(({ settingType, isEnabled }) => [settingType, isEnabled]),
  )

  const shouldNotifyByDefault = (notifySettingType) => (defaultOrgNotifySettings.get(notifySettingType) || false)

  const defaultMemberIds = () => {
    if (!isEmpty(propTask)) return []

    const ids = members.filter((member) => (member.user.active)).map((member) => (member.user.id))

    if (!currentUserIsPhpAdmin) {
      ids.push(currentUserId)
    }

    return [...new Set(ids)]
  }

  const [task, setTask] = useState({
    ...EMPTY_TASK,
    memberIds: defaultMemberIds(),
    notifyIds: shouldNotifyByDefault(MEMBERS_ON_ASSIGNMENT) ? defaultMemberIds() : [],
    notifyOnCompleteIds: shouldNotifyByDefault(MEMBERS_ON_COMPLETE) ? defaultMemberIds() : [],
    ...propTask,
  })

  const addAssigneeDepartment = (lookup) => {
    const { assigneeDepartments, memberDepartments } = task

    if (find(assigneeDepartments, lookup)) return

    // If the new assignee department has the same departmentId
    // that is used for one of the member departments,
    // do not pre-select a segment selected for the member department
    const matchedMemberDepartment = find(memberDepartments, lookup)
    const segments = matchedMemberDepartment
      ? SEGMENTS.filter((segment) => !matchedMemberDepartment.segments.includes(segment)).slice(0, 1)
      : [...DEFAULT_SEGMENTS]

    const addedAssigneeDepartment = {
      ...lookup,
      notify: shouldNotifyByDefault(ASSIGNEES_ON_ASSIGNMENT),
      notifyOnComplete: shouldNotifyByDefault(ASSIGNEES_ON_COMPLETE),
      segments,
    }

    setTask({
      ...task,
      assigneeDepartments: [...assigneeDepartments, addedAssigneeDepartment],
    })
  }

  const addAssigneeDepartmentByDepartmentId = (departmentId) => addAssigneeDepartment({ departmentId })

  const addAssigneeDepartmentByQuestionUUID = (questionUUID) => addAssigneeDepartment({ questionUUID })

  const addAssigneeDepartmentNotification = (identifier) => {
    const { assigneeDepartments } = task
    const updateNotification = () => ({ notify: true })

    const updatedDepartments = updateDepartmentInCollection(assigneeDepartments, identifier, updateNotification)

    setTask({
      ...task,
      assigneeDepartments: updatedDepartments,
    })
  }

  const addAssigneeDepartmentNotificationOnComplete = (identifier) => {
    const { assigneeDepartments } = task
    const updateNotification = () => ({ notifyOnComplete: true })

    const updatedDepartments = updateDepartmentInCollection(assigneeDepartments, identifier, updateNotification)

    setTask({
      ...task,
      assigneeDepartments: updatedDepartments,
    })
  }

  const addAssigneeGroupId = (groupId) => {
    const updatedAssigneeGroupIds = [...new Set(task.assigneeGroupIds).add(groupId)]
    const updatedMemberGroupIds = task.memberGroupIds.filter((id) => id !== groupId)

    const updatedNotifyGroupIds = shouldNotifyByDefault(ASSIGNEES_ON_ASSIGNMENT)
      ? [...new Set(task.notifyGroupIds).add(groupId)]
      : task.notifyGroupIds.filter((id) => id !== groupId)

    const updatedNotifyOnCompleteGroupIds = shouldNotifyByDefault(ASSIGNEES_ON_COMPLETE)
      ? [...new Set(task.notifyOnCompleteGroupIds).add(groupId)]
      : task.notifyOnCompleteGroupIds.filter((id) => id !== groupId)

    setTask({
      ...task,
      assigneeGroupIds: updatedAssigneeGroupIds,
      notifyGroupIds: updatedNotifyGroupIds,
      notifyOnCompleteGroupIds: updatedNotifyOnCompleteGroupIds,
      memberGroupIds: updatedMemberGroupIds,
    })
  }

  const addAssigneeId = (assigneeId) => {
    const updatedAssigneeIds = [...new Set(task.assigneeIds).add(assigneeId)]
    const updatedMemberIds = task.memberIds.filter((id) => id !== assigneeId)

    const updatedNotifyIds = shouldNotifyByDefault(ASSIGNEES_ON_ASSIGNMENT)
      ? [...new Set(task.notifyIds).add(assigneeId)]
      : task.notifyIds.filter((id) => id !== assigneeId)

    const updatedNotifyOnCompleteIds = shouldNotifyByDefault(ASSIGNEES_ON_COMPLETE)
      ? [...new Set(task.notifyOnCompleteIds).add(assigneeId)]
      : task.notifyOnCompleteIds.filter((id) => id !== assigneeId)

    setTask({
      ...task,
      assigneeIds: updatedAssigneeIds,
      notifyIds: updatedNotifyIds,
      notifyOnCompleteIds: updatedNotifyOnCompleteIds,
      memberIds: updatedMemberIds,
    })
  }

  const addMemberDepartment = (lookup) => {
    const { assigneeDepartments, memberDepartments } = task

    if (find(memberDepartments, lookup)) return

    // If the new assignee department has the same departmentId
    // that is used for one of the member departments,
    // do not pre-select a segment selected for the member department
    const matchedAssigneeDepartment = find(assigneeDepartments, lookup)
    const segments = matchedAssigneeDepartment
      ? SEGMENTS.filter((segment) => !matchedAssigneeDepartment.segments.includes(segment)).slice(0, 1)
      : [...DEFAULT_SEGMENTS]

    const addedMemberDepartment = {
      ...lookup,
      notify: shouldNotifyByDefault(MEMBERS_ON_ASSIGNMENT),
      notifyOnComplete: shouldNotifyByDefault(MEMBERS_ON_COMPLETE),
      segments,
    }

    setTask({
      ...task,
      memberDepartments: [...memberDepartments, addedMemberDepartment],
    })
  }

  const addMemberDepartmentByDepartmentId = (departmentId) => addMemberDepartment({ departmentId })

  const addMemberDepartmentByQuestionUUID = (questionUUID) => addMemberDepartment({ questionUUID })

  const addMemberDepartmentNotification = (identifier) => {
    const { memberDepartments } = task
    const updateNotification = () => ({ notify: true })

    const updatedDepartments = updateDepartmentInCollection(memberDepartments, identifier, updateNotification)

    setTask({
      ...task,
      memberDepartments: updatedDepartments,
    })
  }

  const addMemberDepartmentNotificationOnComplete = (identifier) => {
    const { memberDepartments } = task
    const updateNotification = () => ({ notifyOnComplete: true })

    const updatedDepartments = updateDepartmentInCollection(memberDepartments, identifier, updateNotification)

    setTask({
      ...task,
      memberDepartments: updatedDepartments,
    })
  }

  const addMemberGroupId = (groupId) => {
    const updatedMemberGroupIds = [...new Set(task.memberGroupIds).add(groupId)]
    const updatedAssigneeGroupIds = task.assigneeGroupIds.filter((id) => id !== groupId)

    const updatedNotifyGroupIds = shouldNotifyByDefault(MEMBERS_ON_ASSIGNMENT)
      ? [...new Set(task.notifyGroupIds).add(groupId)]
      : task.notifyGroupIds.filter((id) => id !== groupId)

    const updatedNotifyOnCompleteGroupIds = shouldNotifyByDefault(MEMBERS_ON_COMPLETE)
      ? [...new Set(task.notifyOnCompleteGroupIds).add(groupId)]
      : task.notifyOnCompleteGroupIds.filter((id) => id !== groupId)

    setTask({
      ...task,
      memberGroupIds: updatedMemberGroupIds,
      notifyGroupIds: updatedNotifyGroupIds,
      notifyOnCompleteGroupIds: updatedNotifyOnCompleteGroupIds,
      assigneeGroupIds: updatedAssigneeGroupIds,
    })
  }

  const addMemberId = (memberId) => {
    const updatedMemberIds = [...new Set(task.memberIds).add(memberId)]
    const updatedAssigneeIds = task.assigneeIds.filter((id) => id !== memberId)

    const updatedNotifyIds = shouldNotifyByDefault(MEMBERS_ON_ASSIGNMENT)
      ? [...new Set(task.notifyIds).add(memberId)]
      : task.notifyIds.filter((id) => id !== memberId)

    const updatedNotifyOnCompleteIds = shouldNotifyByDefault(MEMBERS_ON_COMPLETE)
      ? [...new Set(task.notifyOnCompleteIds).add(memberId)]
      : task.notifyOnCompleteIds.filter((id) => id !== memberId)

    setTask({
      ...task,
      memberIds: updatedMemberIds,
      notifyIds: updatedNotifyIds,
      notifyOnCompleteIds: updatedNotifyOnCompleteIds,
      assigneeIds: updatedAssigneeIds,
    })
  }

  const addNotifyGroupId = (groupId) => {
    const updatedIds = new Set(task.notifyGroupIds)
    updatedIds.add(groupId)

    setTask({
      ...task,
      notifyGroupIds: Array.from(updatedIds),
    })
  }

  const addNotifyId = (taskUserId) => {
    const updatedIds = new Set(task.notifyIds)
    updatedIds.add(taskUserId)

    setTask({
      ...task,
      notifyIds: Array.from(updatedIds),
    })
  }

  const addNotifyOnCompleteGroupId = (groupId) => {
    setTask({
      ...task,
      notifyOnCompleteGroupIds: [...new Set(task.notifyOnCompleteGroupIds).add(groupId)],
    })
  }

  const addNotifyOnCompleteId = (taskUserId) => {
    setTask({
      ...task,
      notifyOnCompleteIds: [...new Set(task.notifyOnCompleteIds).add(taskUserId)],
    })
  }

  const removeAssigneeDepartment = (identifier) => {
    const { assigneeDepartments } = task
    const updatedDepartments = reject(assigneeDepartments, identifier)

    setTask({
      ...task,
      assigneeDepartments: updatedDepartments,
    })
  }

  const removeAssigneeDepartmentNotification = (identifier) => {
    const { assigneeDepartments } = task
    const updateNotification = () => ({ notify: false })

    const updatedDepartments = updateDepartmentInCollection(assigneeDepartments, identifier, updateNotification)

    setTask({
      ...task,
      assigneeDepartments: updatedDepartments,
    })
  }

  const removeAssigneeDepartmentNotificationOnComplete = (identifier) => {
    const { assigneeDepartments } = task
    const updateNotification = () => ({ notifyOnComplete: false })

    const updatedDepartments = updateDepartmentInCollection(assigneeDepartments, identifier, updateNotification)

    setTask({
      ...task,
      assigneeDepartments: updatedDepartments,
    })
  }

  const removeAssigneeGroupId = (groupId) => {
    const updateAssigneeGroupIds = task.assigneeGroupIds.filter((id) => id !== groupId)
    const updatedNotifyGroupIds = task.notifyGroupIds.filter((id) => id !== groupId)
    const updatedNotifyOnCompleteGroupIds = task.notifyOnCompleteGroupIds.filter((id) => id !== groupId)

    setTask({
      ...task,
      assigneeGroupIds: updateAssigneeGroupIds,
      notifyGroupIds: updatedNotifyGroupIds,
      notifyOnCompleteGroupIds: updatedNotifyOnCompleteGroupIds,
    })
  }

  const removeAssigneeId = (assigneeId) => {
    const updateAssigneeIds = task.assigneeIds.filter((id) => id !== assigneeId)
    const updatedNotifyIds = task.notifyIds.filter((id) => id !== assigneeId)
    const updatedNotifyOnCompleteIds = task.notifyOnCompleteIds.filter((id) => id !== assigneeId)

    setTask({
      ...task,
      assigneeIds: updateAssigneeIds,
      notifyIds: updatedNotifyIds,
      notifyOnCompleteIds: updatedNotifyOnCompleteIds,
    })
  }

  const removeMemberDepartment = (identifier) => {
    const { memberDepartments } = task
    const updatedDepartments = reject(memberDepartments, identifier)

    setTask({
      ...task,
      memberDepartments: updatedDepartments,
    })
  }

  const removeMemberDepartmentNotification = (identifier) => {
    const { memberDepartments } = task
    const updateNotification = () => ({ notify: false })

    const updatedDepartments = updateDepartmentInCollection(memberDepartments, identifier, updateNotification)

    setTask({
      ...task,
      memberDepartments: updatedDepartments,
    })
  }

  const removeMemberDepartmentNotificationOnComplete = (identifier) => {
    const { memberDepartments } = task
    const updateNotification = () => ({ notifyOnComplete: false })

    const updatedDepartments = updateDepartmentInCollection(memberDepartments, identifier, updateNotification)

    setTask({
      ...task,
      memberDepartments: updatedDepartments,
    })
  }

  const removeMemberGroupId = (groupId) => {
    const updateMemberGroupIds = task.memberGroupIds.filter((id) => id !== groupId)
    const updatedNotifyGroupIds = task.notifyGroupIds.filter((id) => id !== groupId)
    const updatedNotifyOnCompleteGroupIds = task.notifyOnCompleteGroupIds.filter((id) => id !== groupId)

    setTask({
      ...task,
      memberGroupIds: updateMemberGroupIds,
      notifyGroupIds: updatedNotifyGroupIds,
      notifyOnCompleteGroupIds: updatedNotifyOnCompleteGroupIds,
    })
  }

  const removeMemberId = (memberId) => {
    const updateMemberIds = task.memberIds.filter((id) => id !== memberId)
    const updatedNotifyIds = task.notifyIds.filter((id) => id !== memberId)
    const updatedNotifyOnCompleteIds = task.notifyOnCompleteIds.filter((id) => id !== memberId)

    setTask({
      ...task,
      memberIds: updateMemberIds,
      notifyIds: updatedNotifyIds,
      notifyOnCompleteIds: updatedNotifyOnCompleteIds,
    })
  }

  const removeNotifyGroupId = (groupId) => {
    const updatedNotifyGroupIds = task.notifyGroupIds.filter((id) => id !== groupId)

    setTask({
      ...task,
      notifyGroupIds: updatedNotifyGroupIds,
    })
  }

  const removeNotifyId = (taskUserId) => {
    const updatedNotifyIds = task.notifyIds.filter((id) => id !== taskUserId)

    setTask({
      ...task,
      notifyIds: updatedNotifyIds,
    })
  }

  const removeNotifyOnCompleteGroupId = (groupId) => {
    setTask({
      ...task,
      notifyOnCompleteGroupIds: task.notifyOnCompleteGroupIds.filter((id) => id !== groupId),
    })
  }

  const removeNotifyOnCompleteId = (taskUserId) => {
    setTask({
      ...task,
      notifyOnCompleteIds: task.notifyOnCompleteIds.filter((id) => id !== taskUserId),
    })
  }

  const selectAssigneeDepartmentSegment = (identifier, segment) => {
    const { assigneeDepartments } = task
    const updateSegments = (dept) => ({ segments: [...new Set(dept.segments).add(segment)] })

    const updatedDepartments = updateDepartmentInCollection(assigneeDepartments, identifier, updateSegments)

    setTask({
      ...task,
      assigneeDepartments: updatedDepartments,
    })
  }

  const deselectAssigneeDepartmentSegment = (identifier, segment) => {
    const { assigneeDepartments } = task
    const updateSegments = (dept) => (
      { segments: (dept.segments ?? []).filter((selected) => selected !== segment) }
    )

    const updatedDepartments = updateDepartmentInCollection(assigneeDepartments, identifier, updateSegments)

    setTask({
      ...task,
      assigneeDepartments: updatedDepartments,
    })
  }

  const selectedAssigneeDepartmentSegments = useMemo(() => {
    const { assigneeDepartments } = task

    return assigneeDepartments.reduce((segmentsByDept, dept) => (
      { ...segmentsByDept, [dept.questionUUID ?? dept.departmentId]: dept.segments }
    ), {})
  }, [task.assigneeDepartments])

  const updateTaskDescription = (newDescription) => setTask({ ...task, description: newDescription })

  const updateTaskDueDate = (newDueDate) => setTask({ ...task, dueDate: newDueDate })

  const updateManageAccessTaskIds = (taskIds) => setTask({ ...task, manageAccessTaskIds: taskIds })

  const addAccessToTask = (taskId) => {
    const { manageAccessTaskIds } = task

    setTask({
      ...task,
      manageAccessTaskIds: [...manageAccessTaskIds, taskId],
    })
  }

  const removeAccessToTask = (taskId) => {
    const { manageAccessTaskIds } = task

    setTask({
      ...task,
      manageAccessTaskIds: manageAccessTaskIds.filter((id) => id !== taskId),
    })
  }

  const selectMemberDepartmentSegment = (identifier, segment) => {
    const { memberDepartments } = task
    const updateSegments = (dept) => ({ segments: [...new Set(dept.segments).add(segment)] })

    const updatedDepartments = updateDepartmentInCollection(memberDepartments, identifier, updateSegments)

    setTask({
      ...task,
      memberDepartments: updatedDepartments,
    })
  }

  const deselectMemberDepartmentSegment = (identifier, segment) => {
    const { memberDepartments } = task
    const updateSegments = (dept) => (
      { segments: (dept.segments ?? []).filter((selected) => selected !== segment) }
    )

    const updatedDepartments = updateDepartmentInCollection(memberDepartments, identifier, updateSegments)

    setTask({
      ...task,
      memberDepartments: updatedDepartments,
    })
  }

  const selectedMemberDepartmentSegments = useMemo(() => {
    const { memberDepartments } = task

    return memberDepartments.reduce((segmentsByDept, dept) => (
      { ...segmentsByDept, [dept.questionUUID ?? dept.departmentId]: dept.segments }
    ), {})
  }, [task.memberDepartments])

  const updateTaskRelativeDueDate = (newRelativeDueDate) => setTask({ ...task, relativeDueDate: newRelativeDueDate })

  const updateTaskSubformId = (newSubformId) => setTask({ ...task, subformId: newSubformId })

  const updateTaskTitle = (newTitle) => setTask({ ...task, title: newTitle })

  // Object exposed to context consumers
  const contextConsumerValue = {
    task,
    taskAssigneeDepartments: task.assigneeDepartments,
    taskAssigneeGroupIds: task.assigneeGroupIds,
    taskAssigneeIds: task.assigneeIds,
    taskDescription: task.description,
    taskDueDate: task.dueDate,
    taskId: task.id,
    taskManageAccessTaskIds: task.manageAccessTaskIds,
    taskMemberDepartments: task.memberDepartments,
    taskMemberGroupIds: task.memberGroupIds,
    taskMemberIds: task.memberIds,
    taskNotifyGroupIds: task.notifyGroupIds,
    taskNotifyIds: task.notifyIds,
    taskNotifyOnCompleteGroupIds: task.notifyOnCompleteGroupIds,
    taskNotifyOnCompleteIds: task.notifyOnCompleteIds,
    taskRelativeDueDate: task.relativeDueDate,
    taskSubformId: task.subformId,
    taskTitle: task.title,
    selectedAssigneeDepartmentSegments,
    selectedMemberDepartmentSegments,
    addAccessToTask,
    addAssigneeDepartmentNotification,
    addAssigneeDepartmentNotificationOnComplete,
    addAssigneeGroupId,
    addAssigneeId,
    addMemberDepartmentNotification,
    addMemberDepartmentNotificationOnComplete,
    addMemberGroupId,
    addMemberId,
    addNotifyGroupId,
    addNotifyId,
    addNotifyOnCompleteGroupId,
    addNotifyOnCompleteId,
    addAssigneeDepartmentByDepartmentId,
    addAssigneeDepartmentByQuestionUUID,
    addMemberDepartmentByDepartmentId,
    addMemberDepartmentByQuestionUUID,
    deselectAssigneeDepartmentSegment,
    deselectMemberDepartmentSegment,
    removeAccessToTask,
    removeAssigneeGroupId,
    removeAssigneeDepartment,
    removeAssigneeDepartmentNotification,
    removeAssigneeDepartmentNotificationOnComplete,
    removeAssigneeId,
    removeMemberDepartment,
    removeMemberDepartmentNotification,
    removeMemberDepartmentNotificationOnComplete,
    removeMemberGroupId,
    removeMemberId,
    removeNotifyGroupId,
    removeNotifyId,
    removeNotifyOnCompleteGroupId,
    removeNotifyOnCompleteId,
    selectAssigneeDepartmentSegment,
    selectMemberDepartmentSegment,
    updateTaskDescription,
    updateTaskDueDate,
    updateManageAccessTaskIds,
    updateTaskRelativeDueDate,
    updateTaskSubformId,
    updateTaskTitle,
  }

  return (
    <TaskFormContext.Provider value={contextConsumerValue}>
      {children}
    </TaskFormContext.Provider>
  )
}

TaskFormContextProvider.propTypes = {
  children: types.node.isRequired,
  task: taskDataForTaskFormShape,
}

export default TaskFormContextProvider
