import React from "react"
import types from "prop-types"
import { XIcon } from "shared/icons"

const DeleteConditionButton = ({ deleteCondition, className = "" }) => (
  <XIcon
    className={className}
    onClick={deleteCondition}
  />
)

DeleteConditionButton.propTypes = {
  className: types.string,
  deleteCondition: types.func.isRequired,
}

export default DeleteConditionButton
