import React from "react"
import types from "prop-types"

const Answer = ({ rawAnswer = "", isNewQuestion = false }) => {
  if (isNewQuestion) return null

  return (
    <div className="answer">
      { rawAnswer }
    </div>
  )
}

Answer.propTypes = {
  rawAnswer: types.oneOfType([types.string, types.number]),
  isNewQuestion: types.bool,
}

export default Answer
