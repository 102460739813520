import React from "react"
import types from "prop-types"
import clsx from "clsx"
import ItemRow from "shared/ItemRow"

const SubmissionQuestion = ({
  children = null,
  className = "",
  description = undefined,
  isNewQuestion = false,
  prompt,
  required = false,
}) => (
  <ItemRow
    className={clsx(className, isNewQuestion && "submission-new-form-question")}
    itemRowHeaderContentClassName="item-row-header-content-submission-question"
    itemRowHeaderContent={(
      <>
        <span className={clsx("grow font-medium text-lg text-dark", required && "required-prompt")}>{prompt}</span>
        {
          isNewQuestion && (
            <span className="font-semibold text-sm text-task-form-color px-1.5 py-1 tracking-wide bg-white rounded-md">
              NEW
            </span>
          )
        }
      </>
    )}
    itemRowBody={(
      <>
        {
          description && <p className="font-normal text-sm mb-4">{description}</p>
        }
        {children}
      </>
    )}
  />
)

SubmissionQuestion.propTypes = {
  children: types.node,
  className: types.string,
  description: types.string,
  isNewQuestion: types.bool,
  prompt: types.string.isRequired,
  required: types.bool,
}

export default SubmissionQuestion
