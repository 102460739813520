import React from "react"
import types from "prop-types"
import { useSelector } from "react-redux"
import { getFormQuestions } from "reduxSlices/formBuilderSlice"
import TaskDepartmentLabel from "."

const TaskDepartmentByQuestionLabel = ({ className = "", departmentOption }) => {
  const allQuestions = useSelector(getFormQuestions)
  const selectedQuestion = allQuestions.find((question) => question.uuid === departmentOption.questionUUID)

  return <TaskDepartmentLabel className={className} labelText={selectedQuestion.prompt} />
}

TaskDepartmentByQuestionLabel.propTypes = {
  className: types.string,
  departmentOption: types.object.isRequired,
}

export default TaskDepartmentByQuestionLabel
