import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { isEqual } from "lodash-es"
import { useSelector } from "react-redux"
import { getListedTaskIds } from "reduxSlices/formSubmissionSlice"

export const taskShortcutPortalContainerId = (taskId) => `activities-sidebar-task-shorcuts-${taskId}`

const ActivitiesSidebar = ({ className = "" }) => {
  const taskIds = useSelector(getListedTaskIds, isEqual)

  return (
    <div className={clsx(className, "flex flex-col")}>
      {
        taskIds.map((taskId) => (
          <div
            key={`task-shortcut-${taskId}`}
            id={`${taskShortcutPortalContainerId(taskId)}`}
            className="mb-2 last:mb-0"
          />
        ))
      }
    </div>
  )
}

ActivitiesSidebar.propTypes = {
  className: types.string,
}

export default ActivitiesSidebar
