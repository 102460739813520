import React from "react"
import types from "prop-types"
import { isEmpty } from "lodash-es"
import LocationDataElement from "shared/Table/TableDataElement/LocationDataElement"

export const formatAnswer = (answer) => {
  if (isEmpty(answer)) {
    return null
  }

  if (Array.isArray(answer)) {
    return answer.join(", ")
  }

  return answer
}

const ChangeInAnswer = ({ answerFrom = undefined, answerTo = undefined }) => {
  if (answerFrom?.department_id || answerTo?.department_id) {
    return (
      <>
        <LocationDataElement value={answerFrom} />
        <p>→</p>
        <LocationDataElement value={answerTo} />
      </>
    )
  }

  return (
    <p>{formatAnswer(answerFrom)} → {formatAnswer(answerTo)}</p>
  )
}

const possibleAnswerTypes = [types.object, types.array, types.string, types.number]

ChangeInAnswer.propTypes = {
  answerFrom: types.oneOfType(possibleAnswerTypes),
  answerTo: types.oneOfType(possibleAnswerTypes),
}

export default ChangeInAnswer
