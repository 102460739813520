import React from "react"
import types from "prop-types"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { CircularIconButton } from "shared/buttons"
import Tooltip from "shared/Tooltip"
import {
  formSubmissionData,
} from "reduxSlices/formSubmissionSlice"

const TOOLTIP_TEXT = "Submission must be reopened before editing"

const EditSubmissionButton = ({ className = "", onClick }) => {
  const { isClosed } = useSelector(formSubmissionData)

  return (
    <Tooltip text={isClosed ? TOOLTIP_TEXT : ""}>
      <CircularIconButton
        className={clsx(className, isClosed && "cursor-not-allowed")}
        disabled={isClosed}
        icon="pencil"
        onClick={onClick}
      />
    </Tooltip>
  )
}

EditSubmissionButton.propTypes = {
  className: types.string,
  onClick: types.func.isRequired,
}

export default EditSubmissionButton
