import React from "react"
import types from "prop-types"
import { sortBy } from "lodash-es"
import { useSelector } from "react-redux"
import { users as getUsers } from "reduxSlices/formSubmissionSlice"
import { useTaskFormContext } from "contexts/TaskFormContext"
/* eslint-disable max-len */
import TaskUserList from "views/FormSubmission/FormSubmissionTabs/ActivitiesContent/TaskForms/TaskFields/UI/TaskUserList"
/* eslint-enable max-len */

const TaskAssigneeList = ({ className = "" }) => {
  const users = useSelector(getUsers)

  const { removeAssigneeId, taskAssigneeIds } = useTaskFormContext()

  if (taskAssigneeIds.length === 0) return null

  const assignedUsers = sortBy(
    taskAssigneeIds.map((assigneeId) => (
      users.find((user) => user.id === assigneeId)
    )),
    ["lastName", "firstName"],
  )

  return (
    <TaskUserList className={className} removeUser={removeAssigneeId} users={assignedUsers} />
  )
}

TaskAssigneeList.propTypes = {
  className: types.string,
}

export default TaskAssigneeList
