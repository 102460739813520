import React from "react"
import types from "prop-types"

const MultiSelectAnswer = ({ rawAnswer = [], isNewQuestion = false }) => {
  if (isNewQuestion) return null

  return (
    <div className="answer">
      { rawAnswer.join(", ") }
    </div>
  )
}

MultiSelectAnswer.propTypes = {
  rawAnswer: types.arrayOf(
    types.oneOfType([types.string, types.number]),
  ),
  isNewQuestion: types.bool,
}

export default MultiSelectAnswer
