import React, { useEffect, useMemo, useState } from "react"
import types from "prop-types"
import LazyLoad, { forceCheck } from "react-lazyload"
import activeBeforeInactive from "utils/activeBeforeInactive"
import { useSCIMResourceManagement } from "hooks"
import { CustomHeaderAccordion, useAccordion } from "shared/accordions"
import { ChevronDown, ChevronUp } from "shared/icons"
import StrikeAround from "shared/StrikeAround"
import { LAST_FIRST } from "shared/NameFormats"
import UserInfo from "shared/UserInfo"
import NameFilter from "shared/NameFilter"
import withReduxProviderHOC from "shared/withReduxProvider"
import UserActiveStatusFilter, {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
} from "./UserActiveStatusFilter"
import UserRoleFilter, {
  ALL_ROLES,
} from "./UserRoleFilter"
import UserMenu from "./UserMenu"
import EmptyUsers from "./EmptyUsers"
import EmptyFilteredUsers from "./EmptyFilteredUsers"
import CreateUserButtons from "./CreateUserButtons"

const goToUserShow = (user) => () => {
  window.location.pathname = `/admin/users/${user.id}`
}

const checkIfActive = (user, activeStatusFilter) => {
  if (activeStatusFilter === ACTIVE_STATUS) {
    return user.active
  }

  if (activeStatusFilter === INACTIVE_STATUS) {
    return !user.active
  }

  return true
}

const filterByRole = (user, roleFilter) => {
  if (roleFilter === ALL_ROLES) return true

  return user.role === roleFilter
}

const filterBySearch = (user, nameFilter) => {
  const matcher = new RegExp(nameFilter, "i")

  if (nameFilter) {
    return matcher.test(user.fullName)
  }

  return true
}

const UserList = ({ users }) => {
  const { usersManagedBySCIM } = useSCIMResourceManagement()

  const [nameFilter, setNameFilter] = useState("")
  const [activeStatusFilter, setActiveStatusFilter] = useState(ACTIVE_STATUS)
  const [roleFilter, setRoleFilter] = useState(ALL_ROLES)
  const [isExpanded, toggleAccordion] = useAccordion(true)

  const filteredUsers = useMemo(() => {
    const filtered = users.filter((user) => (
      checkIfActive(user, activeStatusFilter) && filterByRole(user, roleFilter) && filterBySearch(user, nameFilter)
    ))

    return filtered.sort(activeBeforeInactive)
  }, [activeStatusFilter, roleFilter, nameFilter, users])

  useEffect(() => {
    forceCheck()
  }, [filteredUsers])

  if (users.length === 0) return <EmptyUsers />

  const Chevron = isExpanded ? ChevronDown : ChevronUp

  return (
    <CustomHeaderAccordion
      isExpanded={isExpanded}
      header={(
        <div className="flex-center">
          <StrikeAround className="text-2xl font-semibold pt-6 pb-3 flex-grow">
            Users
          </StrikeAround>
          <span className="pt-6 pb-3">
            <Chevron
              className="h-8 w-8 p-2 ml-2 cursor-pointer"
              onClick={toggleAccordion}
            />
          </span>
        </div>
      )}
      content={(
        <>
          {
            usersManagedBySCIM && (
              <div className="text-sm text-dark text-center space-y-4 mb-4 max-w-screen-sm mx-auto">
                <p>Your organization manages users via SCIM user provisioning.</p>
                <p>
                  To add a new user or modify an existing user&apos;s email, name, or active status
                  <br />
                  please contact your internal support.
                </p>
              </div>
            )
          }
          <div className="mb-2 gap-x-4">
            {
              !usersManagedBySCIM && (
                <CreateUserButtons />
              )
            }
          </div>

          <div className="flex items-center justify-center mb-4 gap-x-4">
            <UserActiveStatusFilter
              onChange={setActiveStatusFilter}
              value={activeStatusFilter}
            />
            <UserRoleFilter
              onChange={setRoleFilter}
              value={roleFilter}
            />
            <NameFilter
              className="w-1/3"
              onChange={setNameFilter}
              value={nameFilter}
            />
          </div>
          <div className="min-h-screen">
            {
              filteredUsers.length > 0 ? filteredUsers.map((user) => (
                <LazyLoad key={`user-row${user.id}`} height={58} offset={100} once resize>
                  <div className="list-row py-3 relative">
                    <UserInfo
                      user={user}
                      nameDisplayFormat={LAST_FIRST}
                      showContentIfInactive
                      onClickCapture={goToUserShow(user)}
                    >
                      {user.role}
                    </UserInfo>
                    <UserMenu user={user} />
                  </div>
                </LazyLoad>
              )) : <EmptyFilteredUsers />
            }
          </div>
        </>
      )}
    />
  )
}

UserList.propTypes = {
  users: types.arrayOf(types.object).isRequired,
}

export default withReduxProviderHOC(UserList)
