import React from "react"
import types from "prop-types"
import { useDispatch } from "react-redux"
import { undeleteFormQuestion } from "reduxSlices/formBuilderSlice"
import { TertiaryButton } from "shared/buttons"

const UndeleteButton = ({ className = "" }) => {
  const dispatch = useDispatch()

  const undeleteQuestion = () => dispatch(undeleteFormQuestion())

  return (
    <TertiaryButton
      className={className}
      icon="fas fa-trash-restore"
      text="Undelete Question"
      onClick={undeleteQuestion}
    />
  )
}

UndeleteButton.propTypes = {
  className: types.string,
}

export default UndeleteButton
