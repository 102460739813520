import React from "react"
import types from "prop-types"
import SvgSpriteIcon from "shared/SvgSpriteIcon"

const ConditionalTag = ({ className = "" }) => (
  <div className={className}>
    <SvgSpriteIcon iconName="form-conditional" className="pr-1" />
    <span className="text-xs text-light">
      CONDITIONAL
    </span>
  </div>
)

ConditionalTag.propTypes = {
  className: types.string,
}

export default ConditionalTag
